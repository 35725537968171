import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../Button";
import { PageSectionContainer } from "../PageSection";
import { Heading } from "../Typography/Heading";
import { Testimonials } from "../Testimonial/Testimonials";
import { useSectionTheme } from "../../styles/pageThemes";

type SanityHomePageSettings = Pick<GatsbyTypes.Query, "sanityHomePageSettings">;

const SectionContainer = styled(PageSectionContainer)`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};

    && {
        padding-top: 48px;
        padding-bottom: 64px;
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
`;

const TestimonialsHeading = styled(Heading)`
    margin: 0px;
`;

export const HomeTestimonialsSection = () => {
    const data: SanityHomePageSettings = useStaticQuery(
        graphql`
            query {
                sanityHomePageSettings {
                    testimonials {
                        title
                        featuredTestimonials {
                            id
                            name
                            quote
                            company
                            profile {
                                alt
                                img {
                                    asset {
                                        gatsbyImageData(
                                            layout: FULL_WIDTH
                                            placeholder: NONE
                                            width: 128
                                        )
                                    }
                                }
                            }
                            logo {
                                asset {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        placeholder: NONE
                                        width: 80
                                    )
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const testimonials =
        data.sanityHomePageSettings.testimonials.featuredTestimonials;
    const title = data.sanityHomePageSettings.testimonials.title;

    return (
        <ThemeProvider theme={useSectionTheme("brightTurquoise")}>
            <SectionContainer>
                <HeadingContainer>
                    <TestimonialsHeading level="h4">
                        {title}
                    </TestimonialsHeading>
                    <Button href="/customers" invert={true}>
                        Case Studies...
                    </Button>
                </HeadingContainer>
                <ThemeProvider theme={useSectionTheme("white")}>
                    <Testimonials testimonials={testimonials} />
                </ThemeProvider>
            </SectionContainer>
        </ThemeProvider>
    );
};
