import styled from "styled-components";
import { gridSquares } from "../../styles/grid";
import Button from "../Button";

export const Card = styled.div`
    background-color: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    padding: ${gridSquares(2)};
    border-radius: ${(p) => p.theme.borderRadius.l};
`;

export const CardColumnLayout = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: flex-start;
    align-items: center;
    gap: ${gridSquares(1)};
    width: 100%;
    text-align: center;

    @media (min-width: ${(p) => `${p.theme.breakpoint.md}px`}) {
        flex-direction: row;
        text-align: left;
    }
`;

export const CardTextContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${gridSquares(0.5)};
    width: 100%;

    @media (min-width: ${(p) => `${p.theme.breakpoint.md}px`}) {
        gap: ${gridSquares(0.25)};
    }
`;

export const CardButton = styled(Button)`
    flex-shrink: 0;
`;
