import { LANDING_PAGE_DESCRIPTION } from "../constants";
import { HomeProductsSection } from "../components/home/HomeProductsSection";
import React from "react";
import { Head } from "../components/Head";
import { HeroSection } from "../components/HeroSection";
import { PromoBanner } from "../components/PromoBanner";
import { HomeTestimonialsSection } from "../components/home/HomeTestimonialsSection";
import { Customers } from "../components/Customers";
import { FeaturedArticles } from "../components/FeaturedArticles";
import { HOME_PAGE_TITLE } from "../constants/titles";
import { ContentGroups } from "../constants/contentGroups";

const HomePage = ({ pageContext }) => {
    return (
        <>
            <Head
                contentGroup={ContentGroups["limited-aware"]}
                title={HOME_PAGE_TITLE}
                description={LANDING_PAGE_DESCRIPTION}
            />
            <HeroSection marketplaceProducts={pageContext.marketplaceData} />
            <HomeProductsSection
                marketplaceProducts={pageContext.marketplaceData}
            />
            <Customers />
            <HomeTestimonialsSection />
            <FeaturedArticles />
            <PromoBanner />
        </>
    );
};

export default HomePage;
