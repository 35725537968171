import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, useTheme } from "styled-components";

import { graphql, navigate, useStaticQuery } from "gatsby";
import Button from "./Button";
import { PageSectionContainer } from "./PageSection";
import { RenderImage } from "./RenderImage";
import { Heading } from "./Typography/Heading";
import { HomeProductsSectionProps } from "../types";
import { generateRating } from "./icons/Rating";
import { BsChevronCompactDown } from "@react-icons/all-files/bs/BsChevronCompactDown";
import { calcWidth } from "../util/theming";
import _ from "lodash";
import { useSectionTheme } from "../styles/pageThemes";

const HeroSectionContainer = styled(PageSectionContainer)`
    background: ${(p) => p.theme.color.primary.main};
    min-height: 100vh;
    padding-bottom: 10px;
    /* support for extra tall viewports, like googlebot 1024x9307px, 411x121400px*/
    @media (min-height: 2000px) {
        min-height: unset !important;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    > :first-child {
        max-width: ${calcWidth(12)}px;
        display: grid;
        align-items: center;
        grid-row-gap: 50px;
        grid-template-areas:
            "valueProposition"
            "graphic";

        ${(p) => {
            return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                grid-template-areas: "valueProposition graphic";
                grid-gap: 80px;
                grid-template-columns: 1fr 1fr;
            }
        `;
        }}
    }
`;

const ValuePropositionWrapper = styled.div`
    grid-area: valueProposition;

    position: relative;
    z-index: 1;

    > * {
        margin-top: 0;
    }
`;

const MyH1 = styled(Heading)`
    color: white;
    margin-bottom: 28px;
    white-space: pre-line;

    ${(p) => {
        return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                margin-bottom: 48px;
            }
        `;
    }}
`;

const Subtitle = styled.p`
    font-size: ${(p) => p.theme.fontSize.heroBannerSubtitle};
    color: white;
    margin-bottom: 38px;
    white-space: pre-line;
`;

const Stats = styled.div`
    margin-top: 30px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:last-child) {
        margin-right: 10px;
    }

    > :last-child {
        padding-left: 10px;
    }
`;

const InstallsText = styled.span`
    align-self: end;
    line-height: 1.125em;
    height: 100%;
`;

const DownChevron = styled(BsChevronCompactDown)`
    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }

    animation: bounce 4s ease-in-out infinite;
    color: white;
    display: none;

    :hover {
        cursor: pointer;
    }

    top: 94vh;
    justify-self: center;
    position: absolute;

    ${(p) => {
        return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                display: block;
            }
        `;
    }}
`;

const HeroBannerImageWrapper = styled.div`
    > :first-child {
        width: 100%;
        height: 100%;
        justify-self: center;
        align-self: center;

        ${(p) => {
            return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                align-content: flex-start;
                width: 100%;
                height: 100%;
            }
        `;
        }}
    }
`;

export const HeroSection = ({
    marketplaceProducts,
}: HomeProductsSectionProps) => {
    const sectionTheme = useSectionTheme("deepViolet");
    const data = useStaticQuery(
        graphql`
            query {
                allSanityHomePageSettings {
                    edges {
                        node {
                            heroBanner {
                                title
                                subtitle
                                ctaText
                                ctaUrl
                                backgroundImage {
                                    lottieFile {
                                        asset {
                                            url
                                        }
                                    }
                                    image {
                                        asset {
                                            gatsbyImageData(placeholder: NONE)
                                        }
                                    }
                                    imageType
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const heroBannerSettings =
        data.allSanityHomePageSettings.edges[0].node.heroBanner;

    const productList = Object.keys(marketplaceProducts);

    function sumReducer(sum, value) {
        return _.round(sum + value, 2);
    }

    const installationTotal = productList
        .map(
            (product) =>
                marketplaceProducts[product].distribution.installationCount
        )
        .reduce(sumReducer, 0);

    const averageRating =
        productList
            .map((product) => marketplaceProducts[product].reviewSummary.rating)
            .reduce(sumReducer, 0) / 4;

    const [showCaret, setShowCaret] = useState(true);

    const listenToScroll = () => {
        const heightToHideFrom = 200;
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        if (winScroll > heightToHideFrom) {
            if (showCaret) {
                setShowCaret(false);
            }
        } else {
            setShowCaret(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    const scrollToProducts = () => {
        window.scroll({
            behavior: "smooth",
            top:
                document
                    .getElementById("productsSection")
                    .getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                40,
        });
    };

    return (
        <>
            <HeroSectionContainer>
                <ThemeProvider theme={sectionTheme}>
                    <ValuePropositionWrapper>
                        <MyH1 level="h1">{heroBannerSettings.title}</MyH1>
                        <Subtitle>{heroBannerSettings.subtitle}</Subtitle>
                        <Button href={heroBannerSettings.ctaUrl}>
                            {heroBannerSettings.ctaText}
                        </Button>
                        <Stats>
                            {generateRating(averageRating, "20")}
                            <InstallsText>
                                {Intl.NumberFormat().format(installationTotal)}{" "}
                                installs
                            </InstallsText>
                        </Stats>
                    </ValuePropositionWrapper>
                    <HeroBannerImageWrapper>
                        <RenderImage
                            loading="eager"
                            fetchpriority="high"
                            imageData={heroBannerSettings.backgroundImage}
                        />
                    </HeroBannerImageWrapper>
                </ThemeProvider>
            </HeroSectionContainer>
            {showCaret && (
                <DownChevron
                    size="80"
                    onClick={() => {
                        scrollToProducts();
                    }}
                />
            )}
        </>
    );
};
