import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Button from "../Button";
import { PageSectionContainer } from "../PageSection";
import { Heading } from "../Typography/Heading";
import { calcWidth } from "../../util/theming";
import { RenderImage } from "../RenderImage";
import { Paragraph } from "../Typography/Paragraph";
import TeamRhythmIcon from "../../../content/imgs/products/TeamRhythmIcon.svg";
import ProgramsIcon from "../../../content/imgs/products/ProgramsIcon.svg";
import RoadmapsIcon from "../../../content/imgs/products/RoadmapsIcon.svg";
import PersonasIcon from "../../../content/imgs/products/PersonasIcon.svg";
import CloudFortifiedLogo from "../../../content/imgs/atlassian/CloudFortifiedLogo.svg";
import {
    PERSONAS_PATH,
    PROGRAMS_PATH,
    ROADMAPS_PATH,
    TEAMRHYTHM_PATH,
} from "../../constants/urls";
import { HomeProductsSectionProps } from "../../types";
import { YoutubeEmbed } from "../EmbeddedContent";

// TODO: Make these dynamic in Sanity later
const teamrhythmDemoUrl = "/product-demo/teamrhythm";
const programsDemoUrl = "/product-demo/programs";

const Container = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-bottom: 80px;
`;

const FeaturedProductsPreamble = styled.div`
    margin-bottom: 80px;
`;

const P = styled.p`
    font-size: 24px;
`;

const ProductInfoSubheading = styled.span`
    margin: 16px 0 4px 0;
    font-weight: ${(p) => p.theme.fontWeight.normal};
`;

const ProductInfoList = styled.ul`
    margin: 4px 0 16px 0;
    font-weight: ${(p) => p.theme.fontWeight.normal};
    list-style-type: circle;
    padding-left: 1em;
`;

const FeaturedProductVideoWrapper = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    border-radius: 24px;
    overflow: hidden;
    grid-area: lottie;
`;

const FeaturedProductContainer = styled.div<{ flipContent?: boolean }>`
    display: grid;
    grid-column-gap: 80px;
    grid-row-gap: 24px;
    grid-template-rows: auto 1fr;

    margin: 40px 0 120px 0;
    align-items: start;

    grid-template-areas:
        "heading"
        "lottie"
        "body";

    ${(p) => p.theme.media("xxl")} {
        ${(p) =>
            p.flipContent
                ? css`
                      grid-template-columns: ${calcWidth(3)}px 1fr;

                      grid-template-areas:
                          "heading lottie"
                          "body lottie";
                  `
                : css`
                      grid-template-columns: 1fr ${calcWidth(3)}px;

                      grid-template-areas:
                          "lottie heading"
                          "lottie body";
                  `}
    }
`;

const FeaturedProductBody = styled.div`
    grid-area: body;
`;

const ProductButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 40px;
`;

const ProductIcon = styled.img`
    height: 64px;
`;

// Icon, installs amount, cloud fortified label...
const ProductMetaInfo = styled.div`
    display: flex;
    grid-area: meta;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    ${(p) => p.theme.media("sm")} {
        justify-content: start;
    }
`;

const ProductMetaInfoText = styled.p``;

const ProductMetaCloudFortified = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 4px;

    img {
        height: 24px;
        margin-left: -3px;
    }

    p {
        // Styles ripped from Atlassian Marketplace site
        text-transform: uppercase;
        color: rgb(52, 69, 99);
        margin-left: 4px;
        font-size: 14px;
        font-weight: ${(p) => p.theme.fontWeight.medium};
    }
`;

const ProductHeadingInfo = styled.div`
    display: grid;
    grid-area: heading;

    grid-template-areas: "title" "meta";

    ${(p) => p.theme.media("sm")} {
        grid-template-areas: "title meta";
    }

    ${(p) => p.theme.media("xxl")} {
        grid-template-areas: "meta" "title";
    }
`;

const SecondaryProductsSection = styled.div`
    margin: 0 0 24px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    @media (min-width: ${(p) => p.theme.breakpoint.lg}px) {
        gap: 80px;
    }
`;

const SecondaryProductsPreamble = styled.div`
    flex: 1 1 ${calcWidth(3)}px;
`;

const SecondaryProducts = styled.div`
    flex: 2 1 ${calcWidth(5)}px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
    gap: 60px;

    @media (min-width: ${(p) => p.theme.breakpoint.md}px) {
        justify-content: space-evenly;
    }
`;

const SecondaryProduct = styled.div`
    gap: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: ${(p) => p.theme.breakpoint.md}px) {
        flex-direction: column;
    }
`;

const SecondaryProductMetaInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 16px;
`;

const installsNumberFormatter = Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
});

// Formats number of installs like "5.1k"
const formatInstalls = (installs: number): string => {
    if (typeof installs !== "number") {
        return "";
    }

    return installsNumberFormatter.format(installs).toLowerCase();
};

export const HomeProductsSection = ({
    marketplaceProducts,
}: HomeProductsSectionProps) => {
    const data = useStaticQuery(
        graphql`
            query {
                settings: sanityHomePageSettings {
                    products {
                        title
                        subtitle
                    }
                }
                teamRhythmVideo: allSanityProduct(
                    filter: { atlassianProductId: { eq: "1212078" } }
                ) {
                    nodes {
                        productPage {
                            featuredVideo {
                                url
                            }
                        }
                    }
                }
                programsVideo: allSanityProduct(
                    filter: { atlassianProductId: { eq: "1219491" } }
                ) {
                    nodes {
                        productPage {
                            featuredVideo {
                                url
                            }
                        }
                    }
                }
            }
        `
    );

    const productsSettings = data.settings.products;

    const storymapsInstalls =
        marketplaceProducts?.TeamRhythm?.distribution?.installationCount || 0;

    const programsInstalls =
        marketplaceProducts?.Programs?.distribution?.installationCount || 0;

    return (
        <Container id="productsSection">
            <FeaturedProductsPreamble>
                <Heading level="h2" levelStyle="h2">
                    {productsSettings.title}
                </Heading>
                <P>{productsSettings.subtitle}</P>
            </FeaturedProductsPreamble>
            <FeaturedProductContainer>
                <FeaturedProductVideoWrapper>
                    <YoutubeEmbed
                        url={
                            data.teamRhythmVideo.nodes[0].productPage
                                .featuredVideo.url
                        }
                        size="large"
                    />
                </FeaturedProductVideoWrapper>
                <ProductHeadingInfo>
                    <ProductMetaInfo>
                        <ProductIcon
                            src={TeamRhythmIcon}
                            alt="Easy Agile TeamRhythm icon"
                        ></ProductIcon>
                        <div>
                            <ProductMetaCloudFortified>
                                <img
                                    src={CloudFortifiedLogo}
                                    alt="Atlassian Cloud Fortified logo"
                                />
                                <p>Cloud Fortified</p>
                            </ProductMetaCloudFortified>
                            {storymapsInstalls && (
                                <ProductMetaInfoText>
                                    {formatInstalls(storymapsInstalls)} installs
                                </ProductMetaInfoText>
                            )}
                        </div>
                    </ProductMetaInfo>
                    <AppTitle level="h3" levelStyle="h3">
                        Easy Agile <br /> TeamRhythm
                    </AppTitle>
                </ProductHeadingInfo>
                <FeaturedProductBody>
                    <Paragraph>
                        Work better as a team to plan and deliver products that
                        your customers love, with our intuitive, agile solution
                        in Jira.
                    </Paragraph>
                    <ProductInfoSubheading>
                        Supporting your team with:
                    </ProductInfoSubheading>
                    <ProductInfoList>
                        <li>Backlog Refinement and Sprint Planning</li>
                        <li>User Story Mapping</li>
                        <li>Team Retrospectives</li>
                    </ProductInfoList>
                    <ProductButtons>
                        <Button href={TEAMRHYTHM_PATH} variation="primary">
                            Features &amp; Pricing
                        </Button>
                        <Button
                            href={teamrhythmDemoUrl}
                            variation="primaryOutline"
                        >
                            Product Tour
                        </Button>
                    </ProductButtons>
                </FeaturedProductBody>
            </FeaturedProductContainer>
            <FeaturedProductContainer flipContent>
                <FeaturedProductVideoWrapper>
                    <YoutubeEmbed
                        url={
                            data.programsVideo.nodes[0].productPage
                                .featuredVideo.url
                        }
                        size="large"
                    />
                </FeaturedProductVideoWrapper>
                <ProductHeadingInfo>
                    <ProductMetaInfo>
                        <ProductIcon
                            src={ProgramsIcon}
                            alt="Easy Agile Programs icon"
                        ></ProductIcon>
                        <div>
                            <ProductMetaCloudFortified>
                                <img
                                    src={CloudFortifiedLogo}
                                    alt="Atlassian Cloud Fortified logo"
                                />
                                <p>Cloud Fortified</p>
                            </ProductMetaCloudFortified>
                            {programsInstalls && (
                                <ProductMetaInfoText>
                                    {formatInstalls(programsInstalls)} installs
                                </ProductMetaInfoText>
                            )}
                        </div>
                    </ProductMetaInfo>
                    <AppTitle level="h3" levelStyle="h3">
                        Easy Agile <br /> Programs
                    </AppTitle>
                </ProductHeadingInfo>
                <FeaturedProductBody>
                    <Paragraph>
                        Easily scale planning and collaboration across teams and
                        timezones with a highly visual &#39;team of teams&#39;
                        tool that seamlessly integrates with Jira.
                    </Paragraph>
                    <ProductInfoSubheading>
                        Set your team up for:
                    </ProductInfoSubheading>
                    <ProductInfoList>
                        <li>PI Planning</li>
                        <li>ART Syncs</li>
                        <li>Scrum of Scrums</li>
                    </ProductInfoList>
                    <ProductButtons>
                        <Button href={PROGRAMS_PATH} variation="primary">
                            Features &amp; Pricing
                        </Button>
                        <Button
                            href={programsDemoUrl}
                            variation="primaryOutline"
                        >
                            Product tour
                        </Button>
                    </ProductButtons>
                </FeaturedProductBody>
            </FeaturedProductContainer>

            <SecondaryProductsSection>
                <SecondaryProductsPreamble>
                    <AppTitle level="h3" levelStyle="h3">
                        Enhance your Jira experience
                    </AppTitle>
                    <Paragraph>
                        Apps that enable your team to align around the product
                        vision with a customer centric approach.
                    </Paragraph>
                </SecondaryProductsPreamble>
                <SecondaryProducts>
                    <SecondaryProduct>
                        <SecondaryProductMetaInfo>
                            <ProductIcon
                                src={RoadmapsIcon}
                                alt="Easy Agile Roadmaps icon"
                            ></ProductIcon>
                            <div>
                                <AppTitle level="h4" levelStyle="h4">
                                    Easy Agile <br /> Roadmaps
                                </AppTitle>
                            </div>
                        </SecondaryProductMetaInfo>
                        <Button href={ROADMAPS_PATH} variation="primary">
                            Features &amp; Pricing
                        </Button>
                    </SecondaryProduct>
                    <SecondaryProduct>
                        <SecondaryProductMetaInfo>
                            <ProductIcon
                                src={PersonasIcon}
                                alt="Easy Agile Personas icon"
                            ></ProductIcon>
                            <div>
                                <AppTitle level="h4" levelStyle="h4">
                                    Easy Agile <br /> Personas
                                </AppTitle>
                            </div>
                        </SecondaryProductMetaInfo>
                        <Button href={PERSONAS_PATH} variation="primary">
                            Features &amp; Pricing
                        </Button>
                    </SecondaryProduct>
                </SecondaryProducts>
            </SecondaryProductsSection>
        </Container>
    );
};

const AppTitle = styled(Heading)`
    grid-area: title;
    margin-top: 0px !important;
    margin-bottom: 16px !important;
    margin-left: auto;
    margin-right: auto;

    ${(p) => p.theme.media("sm")} {
        margin-bottom: 0px !important;
        margin-left: unset;
        margin-right: unset;
    }
`;
