import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "./Button";

import { PageSectionContainer } from "./PageSection";
import { graphql, useStaticQuery } from "gatsby";

import { Heading } from "./Typography/Heading";
import { Paragraph } from "./Typography/Paragraph";

const FillerImage = styled(GatsbyImage)``;

const Container = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-bottom: 80px;
`;

const MainContent = styled.div`
    width: 100%;
`;

const Subtitle = styled(Paragraph)`
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    margin-top: 28px;
    margin-bottom: 28px;
    white-space: pre-line;

    ${(p) => {
        return `
            @media (min-width: ${p.theme.breakpoint.md}px) {
                margin-bottom: 56px;
            }
        `;
    }}
`;

const SectionLayout = styled.div`
    background-color: ${(p) => p.theme.color.secondaryOne.main};
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    position: relative;
    width: 100%;

    ${(p) => p.theme.media("xl")} {
        ${MainContent} {
            width: 50%;
            padding-right: 80px;
        }

        ${FillerImage} {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;

            img {
                object-position: left center;
            }
        }
    }
`;

const SectionHeading = styled(Heading)`
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    white-space: pre-line;

    ${(p) => {
        return ` @media (min-width: ${p.theme.breakpoint.md}px) {
        margin-bottom: 48px;`;
    }}
`;
export const PromoBanner = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allSanityHomePageSettings {
                    edges {
                        node {
                            promoBanner {
                                title
                                subtitle
                                visible
                                ctaText
                                ctaUrl
                                backgroundImage {
                                    imageType
                                    image {
                                        asset {
                                            gatsbyImageData(
                                                placeholder: NONE
                                                layout: FULL_WIDTH
                                            )
                                        }
                                    }
                                    lottieFile {
                                        asset {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const promoBannerSettings =
        data.allSanityHomePageSettings.edges[0].node.promoBanner;

    if (!promoBannerSettings.visible) {
        return null;
    }

    const fillerImage = getImage(
        promoBannerSettings.backgroundImage.image.asset.gatsbyImageData
    );

    return (
        <SectionLayout>
            <FillerImage
                image={fillerImage}
                alt={promoBannerSettings.title}
                aria-hidden
            />
            <Container>
                <MainContent>
                    <SectionHeading levelStyle="h4" level="h4">
                        {promoBannerSettings.title}
                    </SectionHeading>
                    <Subtitle>{promoBannerSettings.subtitle}</Subtitle>
                    <Button
                        variation="lightOutline"
                        href={promoBannerSettings.ctaUrl}
                    >
                        {promoBannerSettings.ctaText}
                    </Button>
                </MainContent>
            </Container>
        </SectionLayout>
    );
};
