import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Button from "./Button";
import { PageSectionContainer } from "./PageSection";
import { Heading } from "./Typography/Heading";
import circleTriangle from "../../content/imgs/Home_Page_Blog_Section_Circle_Tirangle.svg";
import squareCircle from "../../content/imgs/Home_Page_Blog_Section_Square_Circle.svg";
import { ArticleTiles } from "./Articles/ArticleTiles";
import toArticle from "../util/toArticle";
import { useSectionTheme } from "../styles/pageThemes";

const ParentLayout = styled(PageSectionContainer)`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    position: relative;
`;

const SectionHeading = styled(Heading)`
    color: ${(p) => p.theme.color.primary.main};
    margin: 0px;
`;

const SectionHeader = styled.div`
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 50px;
`;

const InnerLayout = styled.div`
    padding-top: 60px;
    padding-bottom: 70px;
`;
const RightShapes = styled.img`
    position: absolute;
    top: -40px;
    right: -90px;
    z-index: 1;
    transform: rotate(21deg);
    width: 150px;

    ${(p) => p.theme.media("lg")} {
        width: 200px;
    }

    ${(p) => p.theme.media("xxl")} {
        width: 250px;
    }

    ${(p) => p.theme.media("xxxl")} {
        right: -200px;
        width: 500px;
    }
`;

const LeftShapes = styled.img`
    position: absolute;
    bottom: -40px;
    left: -25px;
    z-index: 1;
    width: 100px;

    ${(p) => p.theme.media("lg")} {
        width: 125px;
    }

    ${(p) => p.theme.media("xxl")} {
        bottom: -90px;
        left: -50px;
        width: 200px;
    }

    ${(p) => p.theme.media("xxxl")} {
        left: -100px;
        bottom: -150px;
        width: 400px;
    }

    /* position: absolute;
    bottom: -5%;
    right: 68%;
    z-index: 1;
    transform: translate(-20%) scale(1.4);

    ${(p) => {
        return `
         @media (min-width: ${p.theme.breakpoint.md}px) {
                opacity: 0;
            }
             @media (min-width: ${p.theme.breakpoint.lg}px) {
                opacity: 1;
                bottom: -88%;
                left: -18%;
                transform: rotate(-98deg) translate(9%);
            }
            @media (min-width: ${p.theme.breakpoint.xl}px) {
                bottom: -62%;
                left: -9%;
                transform: rotate(-5deg) translate(2%);
            }
             @media (min-width: ${p.theme.breakpoint.xxl}px) {
                    bottom: -60%;
                    left: 5%;
                transform: rotate(-5deg) translate(-40%);


            }
        `;
    }}; */
`;

export const FeaturedArticles = () => {
    const data = useStaticQuery(graphql`
        query {
            allSanityHomePageSettings {
                nodes {
                    blogPosts {
                        blogPosts {
                            title
                            slug {
                                current
                            }
                            featuredImage {
                                img {
                                    asset {
                                        gatsbyImageData(
                                            placeholder: NONE
                                            layout: FULL_WIDTH
                                        )
                                    }
                                }
                            }
                            category {
                                name
                            }
                        }
                        title
                    }
                }
            }
        }
    `);
    const articles = data.allSanityHomePageSettings.nodes[0].blogPosts.blogPosts
        .map(toArticle)
        .slice(0, 3);

    const heading = data.allSanityHomePageSettings.nodes[0].blogPosts.title;

    return (
        <ThemeProvider theme={useSectionTheme("grey")}>
            <ParentLayout>
                <RightShapes src={circleTriangle} alt=""></RightShapes>
                <LeftShapes src={squareCircle} alt=""></LeftShapes>
                <InnerLayout>
                    <SectionHeader>
                        <SectionHeading level="h4" levelStyle="h4">
                            {heading}
                        </SectionHeading>
                        <Button href="/blog">More blogs...</Button>
                    </SectionHeader>
                    <ArticleTiles articles={articles} layout="condensed" />
                </InnerLayout>
            </ParentLayout>
        </ThemeProvider>
    );
};
