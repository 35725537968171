import React from "react";
import styled from "styled-components";
import { Lottie } from "./Lottie";
import { PageSectionContainer } from "./PageSection";
import { Heading } from "./Typography/Heading";
import { LinkButton } from "./Typography/LinkButton";
import { useSectionTheme } from "../styles/pageThemes";
import { ThemeProvider } from "styled-components";
import { gridSquares } from "../styles/grid";
import { Hyperlink } from "./Link/Hyperlink";
const SectionContainer = styled(PageSectionContainer)`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
`;

const MyHeading = styled(Heading)`
    color: ${(p) => p.theme.sectionTheme.fontColor};
    text-align: center;
    padding: ${gridSquares(1)} 0;
    margin: 0px;
`;
const LinkContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: ${gridSquares(0.5)};
    padding-bottom: ${gridSquares(0.5)};
    color: ${(p) => p.theme.sectionTheme.fontColor};
`;

export const Customers = () => {
    return (
        <ThemeProvider theme={useSectionTheme("meteorShower")}>
            <SectionContainer>
                <MyHeading level="h4">
                    Trusted by 150K users worldwide
                </MyHeading>
                <Hyperlink aria-label="View our customers" href="/customers">
                    <Lottie lottieSrc="/images/lottie/CustomersListRoll.json"></Lottie>
                </Hyperlink>
                <LinkContainer>
                    <LinkButton color={"currentColor"} href="/customers">
                        Case Studies
                    </LinkButton>
                </LinkContainer>
            </SectionContainer>
        </ThemeProvider>
    );
};
